<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #05b9ae4d;
}
.prt-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.prt-card-header > div {
  text-align: right;
}
.action-button {
  margin: 0 4px 4px 0;
}
p.footnote {
  color: #aaaaaa;
  font-size: 13px;
}
.btn-unlayer {
  color: #3f4856;
  background-color: rgb(214, 217, 220);
  border-color: rgb(214, 217, 220);
  &:hover {
    color: #343c47;
    background-color: rgb(196, 199, 201);
    border-color: rgb(196, 199, 201);
  }
}
.prt-no-focus-box-shadow:focus {
  outline: 0;
  box-shadow: unset;
}
.mouse-cursor-loading {
  cursor: wait;
}
</style>
