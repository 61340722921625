import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import importCoreUI from './importCoreUI';
import { iconsSet as icons } from './assets/icons/icons.js';
import moment from 'moment';
import 'moment/locale/de.js';
import axios from 'axios';
import commons from './commons';
import toastPlugin from "./toastPlugin";
import realmSwitcherModalPlugin from '@/realmSwitcherModalPlugin';
import modalPlugin from '@/modalPlugin';
import 'vue-search-select/dist/VueSearchSelect.css';
import validations from '@/validations';
import VCalendar from 'v-calendar';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import JsonCSV from 'vue-json-csv';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSlider from "vue-slider-component";
import { Chart } from 'chart.js';
import 'chartjs-adapter-moment';
import CPagination from '@coreui/vue/src/components/pagination/CPagination';
import { Stomp } from '@stomp/stompjs';
import WebSocketService from '@/services/WebSocketService';
import CBreadcrumb from '@coreui/vue';
import CInputGroup from './components/CInputGroup.vue';
import CInputGroupText from './components/CInputGroupText.vue';

moment.locale('de');

Vue.config.performance = true;

importCoreUI.installComponents();
Vue.use(toastPlugin);
Vue.use(realmSwitcherModalPlugin);
Vue.use(modalPlugin);
Vue.prototype.$stomp = Stomp;

Vue.use(VCalendar, {});
Vue.component('VueSlider', VueSlider);
Vue.component('CPagination', CPagination);
Vue.component('CInputGroup', CInputGroup);
Vue.component('CInputGroupText', CInputGroupText);


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBdNZqtEDIsQbckRbf88R-z7fJpTyvyfnk',
    libraries: 'places',
  },
});

Vue.component('multiselect', Multiselect);
Vue.prototype.$moment = moment;
Vue.prototype.$commons = commons;
Vue.prototype.$validations = validations;
Vue.component('downloadCsv', JsonCSV);

WebSocketService.connect();
WebSocketService.subscribeToTopic('/topic/notifications', (message) => {
  const notificationData = JSON.parse(message.body);
  WebSocketService.handleNewNotification(notificationData);
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!commons.isLoggedIn()) {
      commons.setToStorage('redirectAfterLogin', window.location.href);
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta)) {
    const meta = {};
    to.matched.forEach(matchedRoute => {
      _.merge(meta, matchedRoute.meta);
    });

    if (meta.requiredPlan && !commons.hasRealmPlan(meta.requiredPlan)) {
      next({
        name: 'PlanForbidden',
        params: {
          requiredPlan: meta.requiredPlan,
          featureName: meta.featureName
        }
      });
      return;
    }

    if (meta.requiredRole && !commons.hasRole(meta.requiredRole)) {
      next({
        name: 'Page404'
      });
      return;
    }
  }
  next();
});

axios.interceptors.request.use(function (config) {
  let loggedIn = commons.getFromStorage('loggedIn');
  let realmContext = commons.getFromStorage('realmContext');

  if (loggedIn) {
    config.headers['Authorization'] = 'Bearer ' + commons.getFromStorage('jwtToken');
  }
  if (realmContext) {
    if (typeof config.params !== 'object') config.params = {};
    config.params['realmContext'] = realmContext;
  }

  if (!config.baseURL) config.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Interceptor: ' + error);
    if (error.response) {
      if (error.response.status === 401) {
        router.push({ name: 'Logout' });
      }
      if (error.response.status === 403) {
        toastPlugin.makeToast('Sorry!', 'Zugriff verweigert!');
      }
    }
    return Promise.reject(error);
  }
);

if (commons.isLoggedIn()) {
  axios.get('/realm/current')
    .then(response => {
      commons.setToStorage('currentRealm', response.data);
    })
    .catch(err => {
      console.error("Can't retrieve fresh state of current realm");
    });
}

new Vue({
  el: '#app',
  router,
  store,
  icons,
  render: h => h(App)
});

console.log('Store added to Vue instance:', store);