import _ from 'lodash'

const ROLES_MAP = {
    ADMIN: { name: 'ADMIN', permission: 100, title: 'Cloud-Administrator', description: 'Root für das gesamte System. VORSICHT!' },
    ORGANIZATION_ADMIN: { name: 'ORGANIZATION_ADMIN', permission: 20, title: 'Organisation Admin', description: 'Administrator einer Organisation. Hat alle Rechte innerhalb einer Organisation. Hat auf allen Instanzen (unteraccounts) Rechte.' },
    ORGANIZATION_USER: { name: 'ORGANIZATION_USER', permission: 15, title: 'Organisation Nutzer', description: 'Nutzer einer Organisation. Hat lesenden Zugriff innerhalb einer Organisation auf alle Instanzen (unteraccounts) & kann keine Einstellungen ändern.' },
    REALM_ADMIN: { name: 'REALM_ADMIN', permission: 10, title: 'Instanz Admin', description: 'Admin einer Instanz. Hat alle Rechte innerhalb der ausgewählten Instanz.' },
    REALM_USER: { name: 'REALM_USER', permission: 5, title: 'Instanz Nutzer', description: 'Nutzer einer Instanz. Hat lesenden Zugriff innerhalb der ausgewählten Instanz & kann keine Einstellungen ändern.' }
}

const ROLES = Object.values(ROLES_MAP)

const REALM_PLANS = {
    TRIAL: { name: 'TRIAL', weight: 10, backgroundColor: '#65b300' },
    BASIC: { name: 'BASIC', weight: 5, backgroundColor: '#aab3b2' },
    PREMIUM: { name: 'PREMIUM', weight: 10, backgroundColor: '#b3428e' },
    PROFESSIONAL: { name: 'PROFESSIONAL', weight: 20, backgroundColor: '#920000' },
    KENSINGTON: { name: 'KENSINGTON', weight: 21, backgroundColor: '#1D6150' },
}

const LEAD_TYPES = {
    APARTMENT: { name: 'APARTMENT', title: 'Wohnung' },
    HOUSE: { name: 'HOUSE', title: 'Haus' },
    LAND: { name: 'LAND', title: 'Grundstück' },
    RENT: { name: 'RENT', title: 'Miete' },
    BUSINESS: { name: 'APARTMENT', title: 'Gewerbe' },
    BASIC: { name: 'BASIC', title: 'Basic' },
}

// Create our number formatter.
const moneyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const flatObject = input => {
    const getEntries = (o, prefix = '') =>
        Object.entries(o).flatMap(([k, v]) =>
            Object(v) === v ? getEntries(v, `${prefix}${k}.`) : [[`${prefix}${k}`, v]])
    return Object.fromEntries(getEntries(input))
}

const getFromStorage = (key, def) => {
    let item = localStorage.getItem(key)
    if (item !== null) {
        return JSON.parse(item)
    } else {
        return typeof def === 'function' ? def() : def;
    }
}

const setToStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

const removeFromStorage = key => {
    localStorage.removeItem(key)
}

const logout = () => {
    localStorage.clear()
    window.reload(false)
}

const isLoggedIn = () => {
    return getFromStorage('loggedIn', false)
}

// convert multi-nested obj to dot notation strings
const oneLevelObject = (obj, delimiter = '_', prefix = '') => {
    let res = {};
    (function recurse(obj, current) {
        for (let key in obj) {
            let value = obj[key];
            let newKey = (current ? current + delimiter + key : prefix + key)  // joined key with dot
            if(value && !Array.isArray(value) && typeof value === "object") {
                recurse(value, newKey)  // it's a nested object, so do it again
            } else {
                res[newKey] = value  // it's not an object, so set the property
            }
        }
    })(obj)
    return res
}

// converts dot notation to multi-nested object
const deepenOneLevelToNested = (obj, delimiter = '_', prefix = '') => {
    let oo = {}, t, parts, part
    for (let k in obj) {
        let originalK = k;
        t = oo
        if (prefix.length && k.startsWith(prefix)) {
            k = k.substring(prefix.length)
            parts = k.split(delimiter);
            const key = parts.pop()
            while (parts.length) {
                part = parts.shift()
                t = t[part] = t[part] || {}
            }
            t[key] = obj[originalK]
        }
    }
    return oo;
}

const toSnakeCase = text => {
    return text.replace(/\.?([A-Z]+)/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "")
}

const getJwtToken = () => {
    return getFromStorage('jwtTokenParsed', () => {
        const token = getFromStorage('jwtToken')
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        const parsed = JSON.parse(jsonPayload)
        setToStorage('jwtTokenParsed', parsed)
        return parsed
    })
}

const isRole = (role) => {
    const jwt = getJwtToken()
    if (jwt) {
        let result = _.find(jwt.auth, {authority: role})
        if (result) {
            return true
        }
    }
    return false
}

const isSystemAdmin = () => {
    return isRole('ADMIN')
}

const isOrganizationAdmin = () => {
    return isRole('ORGANIZATION_ADMIN')
}

const isOrganizationUser = () => {
    return isRole('ORGANIZATION_USER')
}

const isRealmAdmin = () => {
    return isRole('REALM_ADMIN')
}

const isRealmUser = () => {
    return isRole('REALM_USER')
}

const isAnyAdmin = () => {
    return isSystemAdmin() || isOrganizationAdmin() || isRealmAdmin()
}

const getCurrentRealm = () => {
    return getFromStorage('currentRealm')
}

const getCurrentUser = () => {
    return getFromStorage('currentUser')
}

const getCurrentRole = () => {
    const roleNames = _.map(getJwtToken().auth, 'authority')
    return _.maxBy(roleNames.map((rN) => {
        return ROLES_MAP[rN]
    }), 'permission')
}

const getCurrentPlan = () => {
    const realm = getCurrentRealm()
    if (typeof realm.plan === 'undefined') {
        console.error('Realm does not have a plan')
        return null
    }
    return REALM_PLANS[realm.plan]
}

const calculateRolesLower = (role, includeSelf) => {
    if (includeSelf) {
        return ROLES.filter(r => r.permission <= role.permission)
    } else {
        return ROLES.filter(r => r.permission < role.permission)
    }
}

const hasRole = isRole

const hasRealmPlan = realmPlanName => {
    const realm = getCurrentRealm()
    if (typeof realm.plan === 'undefined') {
        console.error('Realm does not have a plan')
        return false
    }
    const foundRealmPlan = REALM_PLANS[realm.plan]
    const requiredRealmPlan = typeof realmPlanName === 'object' ? realmPlanName : REALM_PLANS[realmPlanName]
    if (typeof foundRealmPlan === 'undefined') throw Error('Realm plan could not be resolved')
    if (typeof requiredRealmPlan === 'undefined') throw Error('Passed realm plan name could not be resolved')
    //console.debug('hasRealmPlan = ' + (foundRealmPlan.weight >= requiredRealmPlan.weight), foundRealmPlan, requiredRealmPlan, realm.plan)
    return foundRealmPlan.weight >= requiredRealmPlan.weight
}

const hasRealmPlanAndRole = (realmPlanName, role) => {
    return hasRealmPlan(realmPlanName) && hasRole(role)
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const formatDate = (d) => {
      const formatStr = ['' + d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)].join('-') + ' ' +
        [('0' + d.getHours()).slice(-2),
            ('0' + d.getMinutes()).slice(-2)].join(':');

      return formatStr
}

const formatStandardDate = (d) => {
    const formatStr = [
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2),
          '' + d.getFullYear(),
      ].join('/') + ' ' +
      [('0' + d.getHours()).slice(-2),
          ('0' + d.getMinutes()).slice(-2)].join(':');

    return formatStr
}

const isJson = item => {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item
    try {
        item = JSON.parse(item)
    } catch (e) {
        return false
    }
    return typeof item === "object" && item !== null
}

const DATE_TIME_FORMAT = 'DD.MM.YYYY, HH:mm [Uhr]'

const formatMoney = money => moneyFormatter.format(money)

const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

const blobToCanvas = (theBlob) => {
    return new Promise((resolve, reject) => {
        const tmpCanvas = document.createElement('canvas')
        const ctx = tmpCanvas.getContext('2d');
        const img = new Image();
        img.onload = event => {
            URL.revokeObjectURL(event.target.src) // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
            ctx.drawImage(img, 0, 0)
            resolve(img)
        }
        img.src = URL.createObjectURL(theBlob);
    })
}

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
function calculateAspectRatioFit (srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth*ratio, height: srcHeight*ratio };
}

function isCurrentRole(role) {
    return getCurrentRole().name === role
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce (func, wait, immediate) {
    let timeout
    return function () {
        let context = this
        let args = arguments
        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

function removeEntitiesWithEmptyValue(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === '') {
            delete obj[key];
        }
    });
}

export default {
    flatObject,
    getFromStorage,
    setToStorage,
    removeFromStorage,
    logout,
    isLoggedIn,
    oneLevelObject,
    deepenOneLevelToNested,
    toSnakeCase,
    isRole,
    isSystemAdmin,
    isOrganizationAdmin,
    isOrganizationUser,
    isRealmAdmin,
    isRealmUser,
    isAnyAdmin,
    hasRole,
    calculateRolesLower,
    getJwt: getJwtToken,
    getCurrentRealm,
    getCurrentRole,
    getCurrentUser,
    getCurrentPlan,
    hasRealmPlan,
    hasRealmPlanAndRole,
    REALM_PLANS,
    ROLES,
    ROLES_MAP,
    numberWithCommas,
    formatDate,
    formatStandardDate,
    DATE_TIME_FORMAT,
    formatMoney,
    LEAD_TYPES,
    isJson,
    blobToFile,
    blobToCanvas,
    calculateAspectRatioFit,
    isCurrentRole,
    debounce,
    removeEntitiesWithEmptyValue
}
