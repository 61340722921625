import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import store from '@/store';  // Import Vuex store

let stompClient = null;
let subscriptionQueue = [];

const connect = () => {
  const socketUrl = process.env.VUE_APP_WEBSOCKET_URL || 'http://localhost:8080/ws';
  console.log('Connecting to WebSocket URL:', socketUrl);
  const socket = new SockJS(socketUrl);
  stompClient = Stomp.over(socket);

  const connectCallback = () => {
    console.log('WebSocket connected');
    processSubscriptionQueue();
  };

  const errorCallback = (error) => {
    console.error('WebSocket error:', error);
    setTimeout(() => connect(), 5000); // Retry connection every 5 seconds
  };

  stompClient.connect({}, connectCallback, errorCallback);
};

const subscribeToTopic = (topic, callback) => {
  if (stompClient && stompClient.connected) {
    console.log(`Subscribing to topic: ${topic}`);
    stompClient.subscribe(topic, callback);
  } else {
    console.log('Queuing subscription for topic:', topic);
    subscriptionQueue.push({ topic, callback });
  }
};

const processSubscriptionQueue = () => {
  while (subscriptionQueue.length > 0 && stompClient && stompClient.connected) {
    const { topic, callback } = subscriptionQueue.shift();
    console.log(`Processing queued subscription for topic: ${topic}`);
    stompClient.subscribe(topic, callback);
  }
};

const handleMailOpened = (leadId) => {
  console.log('Mail opened for lead:', leadId);
  store.dispatch('handleMailOpened', leadId);  // Dispatch to Vuex store
};

const handleNewNotification = (notificationData) => {
  console.log('New notification received:', notificationData);
  store.dispatch('handleNewNotification', notificationData);  // Dispatch to Vuex store
};

const disconnect = () => {
  if (stompClient) {
    stompClient.disconnect(() => {
      console.log('WebSocket disconnected');
    });
  }
};

export default {
  connect,
  disconnect,
  subscribeToTopic,
  handleMailOpened,
  handleNewNotification
};
