import TheToaster from "./containers/TheToaster"

let toasterInstance = null

export default {

    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        let TheToasterComponent = Vue.extend(TheToaster)
        let instance = new TheToasterComponent()
        toasterInstance = instance
        instance.$mount()
        document.body.appendChild(instance.$el)
        Vue.prototype.$toaster = instance
    },

    makeToast(header, content) {
        toasterInstance.makeToast(header, content)
    }

}