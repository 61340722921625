// CoreUI
import {
    CCol,
    CInputCheckbox,
    CRow,
    CInput,
    CSelect,
    CFormGroup,
    CInputFile,
    CButton,
    CDataTable,
    CInputRadio,
    CContainer,
    CFooter,
    CHeader,
    CToggler,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CSubheader,
    CBreadcrumbRouter,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CDropdownDivider,
    CIcon,
    CModal,
    CSpinner,
    CSidebar,
    CSidebarBrand,
    CImg,
    CRenderFunction,
    CToaster,
    CToast,
    CJumbotron,
    CCard,
    CCardHeader,
    CCardBody,
    CBadge,
    CForm,
    CTabs,
    CTab,
    CListGroup,
    CListGroupItem,
    CAlert,
    CTextarea,
    CCardFooter,
    CCollapse,
    CLink,
    CEmbed,
    CCardGroup,
    CWidgetProgress,
    CProgress,
    CWidgetIcon,
    CWidgetProgressIcon,
    CWidgetSimple,
    CWidgetDropdown,
    CSidebarNavTitle,
    CSidebarNavDropdown,
    CSidebarNav,
    CSidebarNavItem,
    CEmitRootEvent,
    CTooltip
} from '@coreui/vue/src';

import Vue from 'vue'

export default {
    installComponents: () => {
        Vue.component('CCol', CCol)
        Vue.component('CInputCheckbox', CInputCheckbox)
        Vue.component('CRow', CRow)
        Vue.component('CInput', CInput)
        Vue.component('CSelect', CSelect)
        Vue.component('CFormGroup', CFormGroup)
        Vue.component('CInputFile', CInputFile)
        Vue.component('CButton', CButton)
        Vue.component('CDataTable', CDataTable)
        Vue.component('CInputRadio', CInputRadio)
        Vue.component('CContainer', CContainer)
        Vue.component('CFooter', CFooter)
        Vue.component('CHeader', CHeader)
        Vue.component('CToggler', CToggler)
        Vue.component('CHeaderBrand', CHeaderBrand)
        Vue.component('CHeaderNav', CHeaderNav)
        Vue.component('CHeaderNavItem', CHeaderNavItem)
        Vue.component('CHeaderNavLink', CHeaderNavLink)
        Vue.component('CSubheader', CSubheader)
        Vue.component('CBreadcrumbRouter', CBreadcrumbRouter)
        Vue.component('CDropdown', CDropdown)
        Vue.component('CDropdownHeader', CDropdownHeader)
        Vue.component('CDropdownItem', CDropdownItem)
        Vue.component('CDropdownDivider', CDropdownDivider)
        Vue.component('CIcon', CIcon)
        Vue.component('CModal', CModal)
        Vue.component('CSpinner', CSpinner)
        Vue.component('CSidebar', CSidebar)
        Vue.component('CSidebarBrand', CSidebarBrand)
        Vue.component('CImg', CImg)
        Vue.component('CRenderFunction', CRenderFunction)
        Vue.component('CToaster', CToaster)
        Vue.component('CToast', CToast)
        Vue.component('CJumbotron', CJumbotron)
        Vue.component('CCard', CCard)
        Vue.component('CCardHeader', CCardHeader)
        Vue.component('CCardBody', CCardBody)
        Vue.component('CBadge', CBadge)
        Vue.component('CForm', CForm)
        Vue.component('CTabs', CTabs)
        Vue.component('CTab', CTab)
        Vue.component('CListGroup', CListGroup)
        Vue.component('CListGroupItem', CListGroupItem)
        Vue.component('CAlert', CAlert)
        Vue.component('CTextarea', CTextarea)
        Vue.component('CCardFooter', CCardFooter)
        Vue.component('CCollapse', CCollapse)
        Vue.component('CLink', CLink)
        Vue.component('CEmbed', CEmbed)
        Vue.component('CCardGroup', CCardGroup)
        Vue.component('CWidgetProgress', CWidgetProgress)
        Vue.component('CProgress', CProgress)
        Vue.component('CWidgetIcon', CWidgetIcon)
        Vue.component('CWidgetProgressIcon', CWidgetProgressIcon)
        Vue.component('CWidgetSimple', CWidgetSimple)
        Vue.component('CWidgetDropdown', CWidgetDropdown)
        Vue.component('CSidebarNavTitle', CSidebarNavTitle)
        Vue.component('CSidebarNavDropdown', CSidebarNavDropdown)
        Vue.component('CSidebarNav', CSidebarNav)
        Vue.component('CSidebarNavItem', CSidebarNavItem)
        Vue.directive('c-emit-root-event', CEmitRootEvent)
        Vue.directive('c-tooltip', CTooltip)
    }
}
