import Vue from 'vue';
import Router from 'vue-router';

// TODO: use auth guard or so?

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');
const Dashboard1 = () => import('@/views/Dashboard1');

const AdminRealms = () => import('@/views/admin/Realms');
const AdminNewRealm = () => import('@/views/admin/NewRealm');
const AdminEditRealm = () => import('@/views/admin/EditRealm');

const Leads = () => import('@/views/base/Leads');
const QueuedLeads = () => import('@/views/base/QueuedLeads');
const EditLead = () => import('@/views/base/EditLead');
const EditQueuedLead = () => import('@/views/base/EditQueuedLead');
const Tasks = () => import('@/views/base/Tasks');

const AdminOrganizations = () => import('@/views/admin/Organizations');
const AdminNewOrganization = () => import('@/views/admin/NewOrganization');
const AdminEditOrganization = () => import('@/views/admin/EditOrganization');
const NotificationManagement = () => import('@/views/admin/NotificationManagement.vue');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const PageEditor = () => import('@/views/pages/PageEditor');
const Login = () => import('@/views/pages/Login');
const Logout = () => import('@/views/pages/Logout');
const UserRegisterByToken = () => import('@/views/pages/UserRegisterByToken');
const ForgotPassword = () => import('@/views/pages/ForgotPassword');
const PasswordResetByToken = () => import('@/views/pages/PasswordResetByToken');

const IframeSettings = () => import('@/views/iframe/IframeSettings');
const RealmSettings = () => import('@/views/base/RealmSettings');
const PdfSettings = () => import('@/views/base/PdfSettings');
const Support = () => import('@/views/base/form');
const ChatbotSettings = () => import('@/views/chatbot/ChatbotSettings');
const SeoAudit = () => import('@/views/base/SeoAudit');
const ImportExport = () => import('@/views/base/ImportExport');
const PlanSettings = () => import('@/views/base/PlanSettings');
const Users = () => import('@/views/base/Users');
const NewUser = () => import('@/views/base/NewUser');
const EditUser = () => import('@/views/base/EditUser');
const NewsletterSubscribers = () => import('@/views/base/newsletter/NewsletterSubscribers');
const NewsletterSubscriberEdit = () => import('@/views/base/newsletter/NewsletterSubscriberEdit');
const NewsletterSubscriberNew = () => import('@/views/base/newsletter/NewsletterSubscriberNew');
const NewsletterGroups = () => import('@/views/base/newsletter/NewsletterGroups');
const NewsletterGeneratorPage = () => import('@/views/base/newsletter/NewsletterGeneratorPage');
const NewsletterOptIn = () => import('@/views/base/newsletter/NewsletterOptIn');
const NewsletterArticles = () => import('@/views/base/newsletter/NewsletterArticles');
const NewsletterArticleEdit = () => import('@/views/base/newsletter/NewsletterArticleEdit');
const NewsletterArticleNew = () => import('@/views/base/newsletter/NewsletterArticleNew');

const Pipeline = () => import('@/views/base/Pipeline');
const Deals = () => import('@/views/base/Deals');

const People = () => import('@/views/base/People');
const Organization = () => import('@/views/base/Organization');

const Statistics = () => import('@/views/admin/Statistics');
const Funnel = () => import('@/views/admin/Funnel');
const EditFunnel = () => import('@/views/admin/EditFunnel');
const NewFunnel = () => import('@/views/admin/NewFunnel');

const LandingPages = () => import('@/views/base/landingpage/LandingPages');
const LandingPageNew = () => import('@/views/base/landingpage/LandingPageNew');
const LandingPageEdit = () => import('@/views/base/landingpage/LandingPageEdit');

const LeadgeneratorList = () => import('@/views/base/leadgenerator/LeadgeneratorList');
const LeadgeneratorNew = () => import('@/views/base/leadgenerator/LeadgeneratorNew');
const LeadgeneratorEdit = () => import('@/views/base/leadgenerator/LeadgeneratorEdit');

const KensingtonLocations = () => import('@/plugin/kensington/KensingtonLocations');
const KensingtonLocationNew = () => import('@/plugin/kensington/KensingtonLocationNew');
const KensingtonLocationEdit = () => import('@/plugin/kensington/KensingtonLocationEdit');
const KensingtonLocationImportPage = () => import('@/plugin/kensington/KensingtonLocationImportPage');

const SocialMediaPosts = () => import('@/views/base/social-media/SocialMediaPosts');
const SocialMediaPostNew = () => import('@/views/base/social-media/SocialMediaPostNew');
const SocialMediaPostEdit = () => import('@/views/base/social-media/SocialMediaPostEdit');
const SocialMediaConnections = () => import('@/views/base/social-media/SocialMediaConnections');
const SocialMediaHilfe = () => import('@/views/base/social-media/SocialMediaHelp');

const PlanForbidden = () => import('@/views/admin/PlanForbidden');

Vue.use(Router);

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'dashboard1',
          name: 'Dashboard1',
          component: Dashboard1
        },
        {
          path: 'page-editor',
          name: 'PageEditor',
          component: PageEditor
        },
        {
          path: 'admin',
          redirect: '/admin/realms',
          name: 'Admin',
          meta: {
            requiredRole: 'ADMIN'
          },
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: 'realms',
              name: 'Realms',
              component: AdminRealms
            },
            {
              path: 'realms/new',
              name: 'New realm',
              component: AdminNewRealm
            },
            {
              path: 'realms/edit/:id',
              name: 'Edit realm',
              component: AdminEditRealm
            },
            {
              path: 'users',
              name: 'AdminUsers',
              component: Users
            },
            {
              path: 'organizations',
              name: 'Organizations',
              component: AdminOrganizations
            },
            {
              path: 'organizations/edit/:id',
              name: 'Edit organization',
              component: AdminEditOrganization
            },
            {
              path: 'organizations/new',
              name: 'New organizations',
              component: AdminNewOrganization
            },
            {
              path: 'notifications',
              name: 'NotificationManagement',
              component: NotificationManagement,
              meta: {
                label: 'Benachrichtigungen verwalten',
                requiredRole: 'ADMIN'
              }
            }
          ]
        },
        {
          path: 'leads',
          name: 'Leads',
          component: Leads,
          meta: {
            label: 'Leads'
          }
        },
        {
          path: 'tasks',
          name: 'Tasks',
          component: Tasks,
          meta: {
            label: 'Tasks',
            //requiredPlan 'PROFESSIONAL'
          }
        },
        {
          path: 'deals',
          redirect: '/deals/index',
          name: 'Deals',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          meta: {
            label: 'Deals'
          },
          children: [
            {
              path: 'index',
              name: 'DealsIndex',
              component: Deals,
              meta: {
                label: 'Deals',
                //requiredPlan 'PROFESSIONAL'
              }
            },
            {
              path: 'pipeline',
              name: 'Pipeline',
              component: Pipeline,
              meta: {
                label: 'Pipeline',
                //requiredPlan 'PROFESSIONAL'
              }
            }
          ]
        },
        {
          path: 'queuedLeads',
          name: 'QueuedLeads',
          component: QueuedLeads,
          meta: {
            label: 'Freizugebene Leads'
          }
        },
        {
          path: 'queuedLeads/edit/:id',
          name: 'Edit queued lead',
          component: EditQueuedLead,
          meta: {
            label: 'Lead bearbeiten'
          }
        },
        {
          path: 'leads/edit/:id',
          name: 'Edit Lead',
          component: EditLead,
          meta: {
            label: 'Lead bearbeiten'
          }
        },
        {
          path: 'contacts',
          redirect: '/contacts/people',
          name: 'Contacts',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {
            label: 'Contacts',
            //requiredPlan 'PROFESSIONAL'
          },
          children: [
            {
              path: 'people',
              name: 'People',
              component: People,
              meta: {
                label: 'People',
                //requiredPlan 'PROFESSIONAL'
              }
            },
            {
              path: 'organization',
              name: 'Organization',
              component: Organization,
              meta: {
                label: 'Organization',
                //requiredPlan 'PROFESSIONAL'
              }
            },
          ]
        },
        {
          path: 'iframe',
          name: 'Iframe Settings',
          component: IframeSettings,
          meta: {
            label: 'Iframe Einstellungen'
          }
        },
        {
          path: 'statistics',
          name: 'Statistics',
          component: Statistics,
          meta: {
            label: 'Statistiken'
          }
        },
        {
          path: 'funnel',
          name: 'Funnel',
          component: Funnel,
          meta: {
            label: 'E-Mail Kampagnen',
            //requiredPlan 'PREMIUM',
            featureName: 'E-Mail Kampagnen'
          },
        },
        {
          path: 'funnel/edit/:id',
          name: 'Edit Funnel',
          component: EditFunnel,
          meta: {
            label: 'Kampagne bearbeiten'
          }
        },
        {
          path: 'funnel/new',
          name: 'New Funnel',
          component: NewFunnel,
          meta: {
            label: 'Neue Kampagne'
          }
        },
        {
          path: 'landingpage',
          name: 'Landingpages',
          component: LandingPages,
          meta: {
            label: 'Landingpages'
          }
        },
        {
          path: 'landingpage/new',
          name: 'Landingpage erstellen',
          component: LandingPageNew,
          meta: {
            label: 'Landingpage erstellen'
          }
        },
        {
          path: 'landingpage/edit/:id',
          name: 'Landingpage bearbeiten',
          component: LandingPageEdit,
          meta: {
            label: 'Landingpage bearbeiten'
          }
        },
        {
          path: 'leadGenerator',
          name: 'LeadgeneratorList',
          component: LeadgeneratorList,
          meta: {
            label: 'Leadgenerator'
          }
        },
        {
          path: 'leadGenerator/new',
          name: 'LeadgeneratorNew',
          component: LeadgeneratorNew,
          meta: {
            label: 'Leadgenerator erstellen'
          }
        },
        {
          path: 'leadGenerator/edit/:id',
          name: 'LeadgeneratorEdit',
          component: LeadgeneratorEdit,
          meta: {
            label: 'Funnel bearbeiten'
          }
        },
        {
          path: 'socialMedia',
          name: 'SocialMediaPosts',
          component: SocialMediaPosts,
          meta: {
            label: 'Social Media'
          }
        },
        {
          path: 'socialMedia/post/new',
          name: 'SocialMediaPostNew',
          component: SocialMediaPostNew,
          meta: {
            label: 'Beitrag erstellen'
          }
        },
        {
          path: 'socialMedia/post/edit/:id',
          name: 'SocialMediaPostEdit',
          component: SocialMediaPostEdit,
          meta: {
            label: 'Beitrag bearbeiten'
          }
        },
        {
          path: 'socialMedia/connections',
          name: 'SocialMediaConnections',
          component: SocialMediaConnections,
          meta: {
            label: 'Social Media'
          }
        },
        {
          path: '/socialMedia/hilfe',
          name: 'SocialMediaHilfe',
          component: SocialMediaHilfe,
        },
        {
          path: 'settings',
          redirect: '/settings/general',
          name: 'Settings',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {
            label: 'Einstellungen'
          },
          children: [
            {
              path: 'general',
              name: 'RealmSettings',
              component: RealmSettings,
              meta: {
                label: 'Instanz Einstellungen'
              }
            },
            {
              path: 'import',
              name: 'Import & Export',
              component: ImportExport,
              meta: {
                label: 'Import & Export'
              }
            },
            {
              path: 'plan',
              name: 'PlanSettings',
              component: PlanSettings,
              meta: {
                label: 'Tarif'
              }
            }
          ]
        },
        {
          path: 'pdf-settings',
          name: 'PDF Settings',
          component: PdfSettings,
          meta: {
            label: 'PDF Einstellungen'
          }
        },
        {
          path: 'form',
          name: 'Support',
          component: Support,
          meta: {
            label: 'Support'
          }
        },
        {
          path: 'chatbot-settings',
          name: 'Chatbot Settings',
          component: ChatbotSettings,
          meta: {
            label: 'Chatbot Einstellungen',
            //requiredPlan 'PREMIUM',
            featureName: 'Chatbot'
          },
        },
        {
          path: 'seo-audit',
          name: 'SEO Audit',
          component: SeoAudit,
          meta: {
            label: 'SEO Audit ',
            featureName: 'SEO Audit'
          },
        },
        {
          path: 'newsletter',
          redirect: '/newsletter/article',
          name: 'Newsletter',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {
            label: 'Newsletter',
            //requiredPlan 'PREMIUM',
            featureName: 'E-Mail Marketing'
          },
          children: [
            {
              path: 'article',
              name: 'NewsletterArticles',
              component: NewsletterArticles,
              meta: {
                label: 'Artikel',
                // requiredPlan: 'PREMIUM'
              }
            },
            {
              path: 'article/edit/:id',
              name: 'NewsletterArticleEdit',
              component: NewsletterArticleEdit,
              meta: {
                label: 'Newsletter bearbeiten',
                //requiredPlan 'PREMIUM'
              }
            },
            {
              path: 'article/new',
              name: 'NewsletterArticleNew',
              component: NewsletterArticleNew,
              meta: {
                label: 'Newsletter erstellen',
                // requiredPlan: 'PREMIUM'
              }
            }
          ]
        },
        {
          path: 'emailBasic',
          redirect: '/emailBasic/subscriber',
          name: 'EmailBasic',
          component: {
            render(c) { return c('router-view') }
          },
          meta: {
            label: 'Newsletter',
            requiredPlan: 'PREMIUM',
            featureName: 'E-Mail Marketing'
          },
          children: [
            {
              path: 'subscriber',
              name: 'NewsletterSubscribers',
              component: NewsletterSubscribers,
              meta: {
                label: 'Abonnenten',
                // requiredPlan: 'PREMIUM'
              }
            },
            {
              path: 'subscriber/edit/:id',
              name: 'NewsletterSubscriberEdit',
              component: NewsletterSubscriberEdit,
              meta: {
                label: 'Abonnent bearbeiten',
                // requiredPlan: 'PREMIUM'
              }
            },
            {
              path: 'subscriber/new',
              name: 'NewsletterSubscriberNew',
              component: NewsletterSubscriberNew,
              meta: {
                label: 'Abonnenten anlegen',
                // requiredPlan: 'PREMIUM'
              }
            },
            {
              path: 'groups',
              name: 'NewsletterGroups',
              component: NewsletterGroups,
              meta: {
                label: 'Gruppen',
                // requiredPlan: 'PREMIUM'
              }
            },
            {
              path: 'generator',
              name: 'Generator',
              component: NewsletterGeneratorPage,
              meta: {
                label: 'Generator',
                //requiredPlan 'PREMIUM'
              }
            },
            {
              path: 'opt-in',
              name: 'Opt-In',
              component: NewsletterOptIn,
              meta: {
                label: 'Opt-In',
                //requiredPlan 'PREMIUM'
              }
            },
          ]
        },
        {
          path: 'users',
          name: 'Users',
          component: Users,
          props: {
            showIds: false
          },
          meta: {
            label: 'Benutzer',
            requiredRole: 'REALM_ADMIN'
          },
        },
        {
          path: 'users/new',
          name: 'New user',
          component: NewUser,
          meta: {
            label: 'Benutzer hinzufügen',
            requiredRole: 'REALM_ADMIN'
          },
        },
        {
          path: 'users/edit/:id',
          name: 'Edit user',
          component: EditUser,
          meta: {
            label: 'Benutzer editieren',
            requiredRole: 'REALM_ADMIN'
          },
        },
        {
          path: 'kensington-locations',
          name: 'KensingtonLocations',
          component: KensingtonLocations,
          meta: {
            label: 'Kensington Standorte',
            requiredRole: 'ORGANIZATION_ADMIN',
            requiredPlan: 'KENSINGTON'
          },
        },
        {
          path: 'kensington-locations/new',
          name: 'KensingtonLocationNew',
          component: KensingtonLocationNew,
          meta: {
            label: 'Kensington Standort hinzufügen',
            requiredRole: 'ORGANIZATION_ADMIN',
            requiredPlan: 'KENSINGTON'
          },
        },
        {
          path: 'kensington-locations/edit/:id',
          name: 'KensingtonLocationEdit',
          component: KensingtonLocationEdit,
          meta: {
            label: 'Kensington Standorte',
            requiredRole: 'ORGANIZATION_ADMIN',
            requiredPlan: 'KENSINGTON'
          },
        },
        {
          path: 'kensington-locations-import',
          name: 'KensingtonLocationImportPage',
          component: KensingtonLocationImportPage,
          meta: {
            label: 'Kensington Import',
            requiredRole: 'ORGANIZATION_ADMIN',
            requiredPlan: 'KENSINGTON'
          },
        },
        {
          path: 'forbidden',
          name: 'PlanForbidden',
          component: PlanForbidden,
          meta: {
            label: 'Zugriff verwährt'
          }
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'userRegister',
          name: 'UserRegister',
          component: UserRegisterByToken
        },
        {
          path: 'forgotPassword',
          name: 'ForgotPassword',
          component: ForgotPassword
        },
        {
          path: 'passwordResetByToken',
          name: 'PasswordResetByToken',
          component: PasswordResetByToken
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout
        }
      ]
    },
    {
      path: '*',
      redirect: '/pages/404',
      name: 'EverythingElse',
      component: {
        render(c) { return c('router-view') }
      },
    }
  ]
}
