import TheRealmSwitcherModal from "./containers/TheRealmSwitcherModal"

let realmSwitcherModalInstance = null

export default {

  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    let TheRealmSwitcherModalComponent = Vue.extend(TheRealmSwitcherModal)
    let instance = new TheRealmSwitcherModalComponent()
    realmSwitcherModalInstance = instance
    instance.$mount()
    document.body.appendChild(instance.$el)
    Vue.prototype.$realmSwitcherModal = instance
  }

}