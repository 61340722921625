import axios from 'axios';
import toastPlugin from '@/toastPlugin';
import moment from 'moment-timezone';


const state = {
  notifications: []
};

const getters = {
  allNotifications: state => state.notifications
};

const actions = {
  async fetchNotifications({ commit }) {
  try {
    const response = await axios.get('/api/notifications');
    if (Array.isArray(response.data)) {
      const formattedNotifications = response.data.map(notification => ({
        ...notification,
        createdAt: moment(notification.createdAt).format('YYYY-MM-DDTHH:mm:ssZ'),
        expiresAt: notification.expiresAt ? moment(notification.expiresAt).format('YYYY-MM-DDTHH:mm:ssZ') : null
      }));
      commit('SET_NOTIFICATIONS', formattedNotifications);
    } else {
      console.error('Expected response.data to be an array, but got:', response.data);
      commit('SET_NOTIFICATIONS', []);
    }
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
},


  async createNotification({ commit }, notification) {
    console.time('createNotification');
    try {
      console.time('apiCall');
      // Konvertieren Sie das Datum in die richtige Zeitzone, bevor Sie es an den Server senden
      const localDate = moment().tz('Europe/Berlin').format();
      const notificationWithCorrectTime = {
        ...notification,
        createdAt: localDate
      };
      const response = await axios.post('/api/notifications', notificationWithCorrectTime);
      console.timeEnd('apiCall');
      // Konvertieren Sie das Datum zurück in die lokale Zeitzone, wenn Sie es vom Server erhalten
      const correctedNotification = {
        ...response.data,
        createdAt: moment(response.data.createdAt).tz('Europe/Berlin').format()
      };
      console.time('commitMutation');
      commit('ADD_NOTIFICATION', correctedNotification);
    } catch (error) {
      console.error('Error creating notification:', error);

      console.error('Error creating notification:', error);
    } finally {
      console.timeEnd('createNotification');
    }
  },
  

  async deleteNotification({ commit }, id) {
    try {
      await axios.delete(`/api/notifications/${id}`);
      commit('REMOVE_NOTIFICATION', id);
      toastPlugin.makeToast('Erfolg', 'Benachrichtigung erfolgreich gelöscht.');
    } catch (error) {
      console.error('Error deleting notification:', error);
      toastPlugin.makeToast('Fehler', 'Fehler beim Löschen der Benachrichtigung.');
    }
  },

  async markNotificationAsRead({ commit }, id) {
    try {
      await axios.put(`/api/notifications/${id}/read`);
      commit('MARK_AS_READ', id);
      toastPlugin.makeToast('Erfolg', 'Benachrichtigung als gelesen markiert.');
    } catch (error) {
      console.error('Error marking notification as read:', error);
      toastPlugin.makeToast('Fehler', 'Fehler beim Markieren der Benachrichtigung als gelesen.');
    }
  },

  handleNewNotification({ commit }, notification) {
    commit('ADD_NOTIFICATION', notification);
  },

  addNotification({ commit }, notification) {
    commit('ADD_NOTIFICATION', notification);
  },

  removeNotification({ commit }, id) {
    commit('REMOVE_NOTIFICATION', id);
  }
};

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },

  ADD_NOTIFICATION(state, notification) {
    const existingIndex = state.notifications.findIndex(n => n.id === notification.id);
    if (existingIndex === -1) {
      state.notifications.unshift(notification);
    } else {
      state.notifications = [
        ...state.notifications.slice(0, existingIndex),
        { ...notification },
        ...state.notifications.slice(existingIndex + 1)
      ];
    }
  },

  REMOVE_NOTIFICATION(state, id) {
    state.notifications = state.notifications.filter(notification => notification.id !== id);
  },

  MARK_AS_READ(state, id) {
    const index = state.notifications.findIndex(notification => notification.id === id);
    if (index !== -1) {
      state.notifications = [
        ...state.notifications.slice(0, index),
        { ...state.notifications[index], read: true },
        ...state.notifications.slice(index + 1)
      ];
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
