import DefaultModal from '@/modals/DefaultModal'
import DeleteModal from '@/modals/DeleteModal'
import LoadingModal from '@/modals/LoadingModal'

const modals = {
  'default': null,
  'delete': null,
  'loading': null
}

function showModal(modal, title, entities, onConfirm, onCancel, options) {
  let chosen = modals[modal]
  if (title) chosen.$data.title = title
  if (entities) chosen.$data.entities = entities
  if (onConfirm) chosen.$data.onConfirm = onConfirm
  if (onCancel) chosen.$data.onCancel = onCancel
  if (options) chosen.$data.options = Object.assign({}, chosen.$data.options, options)
  chosen.$data.show = true
}

function showModalOnly(modal) {
  let chosen = modals[modal]
  chosen.$data.show = true
}

function hideModal(modal) {
  let chosen = modals[modal]
  chosen.$data.show = false
}

export default {

  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    let DefaultModalComp = Vue.extend(DefaultModal)
    modals.default = new DefaultModalComp()
    modals.default.$mount()
    document.body.appendChild(modals.default.$el)

    let DeleteModalComp = Vue.extend(DeleteModal)
    modals.delete = new DeleteModalComp()
    modals.delete.$mount()
    document.body.appendChild(modals.delete.$el)

    let LoadingModalComp = Vue.extend(LoadingModal)
    modals.loading = new LoadingModalComp
    modals.loading.$mount()
    document.body.appendChild(modals.loading.$el)

    Vue.prototype.$modal = {
      showModal,
      showModalOnly,
      hideModal
    }
  }

}
