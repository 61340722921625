<template>
    <div class="input-group-text">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CInputGroupText'
  }
  </script>
  
  <style scoped>
  .input-group-text {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
  }
  </style>
  